/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useState } from "react"
import MainMenu from "./MainMenu"
import { graphql, StaticQuery } from "gatsby"
import Helmet from "react-helmet"
import styled, { createGlobalStyle } from "styled-components"
import Footer from "./Footer"
import { config, animated, useSpring } from "react-spring"
import { Location } from "@reach/router"
import ResponsiveNav from "./ResponsiveNav"
import SocialIcons from "./SocialIcons"

const GlobalStyles = createGlobalStyle`
@import url('https://fonts.googleapis.com/css?family=Poppins:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i&display=swap');

body {
  font-family: 'Open Sans', sans-serif;
  background: #f5f5f5;
  color: #494949;
  margin: 0!important;
  padding: 0!important;
  -webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale;
}
`

const LayoutWrapper = styled(animated.div)`
  position: relative;
  max-width: 1284px;
  margin: 0 auto;
  padding: 0;
`

const ResponsiveNavigation = styled(ResponsiveNav)`
  position: absolute;
  background-color: red;
  display: block;

  .nav-wrapper {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    padding: 100px;
    background: var(--purp);
    z-index: 10;
  }

  .nav-wrapper a {
    display: block;
    text-align: left;
    font-size: 4rem;
    color: white;
    text-decoration: none;
    transition: 0.3s ease border;
    border-bottom: solid 4px transparent;
  }

  .nav-wrapper a:hover {
    border-bottom: solid 4px var(--teal);
  }
`
const HamburgerWrapper = styled.div`
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  height: 85px;
  width: 85px;
  background-color: #e0dfdf;
  z-index: 10001;

  @media (max-width: 768px) {
    display: block;
  }
`

const Hamburger = styled.div`
  position: fixed;
  top: 32px;
  right: 28px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-end;
  height: 18px;
  width: 30px;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  box-sizing: border-box;
  z-index: 10001;

  * {
    -webkit-transition: background-color 0.2s ease;
    -ms-transition: background-color 0.2s ease;
    transition: background-color 0.2s ease;
  }
  &:hover * {
    background-color: #41aa0a;
    &:nth-child(2) {
      width: 26px;
    }
  }
  &:active * {
    background-color: #41aa0a;
    width: 28px;
  }
  &:focus {
    outline: none;
  }
`

const TogglButtonLine = styled.div`
  width: 26px;
  height: 2px;
  background-color: #171717;
  &:nth-child(2) {
    width: 20px;
    transition: width 0.2s ease-in-out;
  }
`

const Layout = ({ children }) => {
  const pageAnimation = useSpring({
    config: config.slow,
    from: { opacity: 0 },
    to: { opacity: 1 },
  })

  const [isNavOpen, setNavOpen] = useState(false)
  const navAnimation = useSpring({
    transform: isNavOpen
      ? `translate3d(0,0,0) scale(1)`
      : `translate3d(100%,0,0) scale(0.6)`,
  })

  return (
    <>
      <StaticQuery
        query={graphql`
          {
            allWordpressWpFavicon {
              edges {
                node {
                  url {
                    source_url
                  }
                }
              }
            }
          }
        `}
        render={props => (
          <Helmet>
            <link
              rel="icon"
              href={props.allWordpressWpFavicon.edges[0].node.url.source_url}
            />
            <meta
              name="description"
              content="Philip Lowe - UX Developer / Product Designer"
            />
          </Helmet>
        )}
      />
      <GlobalStyles />
      <HamburgerWrapper>
        <Hamburger
          onClick={() => setNavOpen(!isNavOpen)}
          className="menu-button"
        >
          <TogglButtonLine />
          <TogglButtonLine />
          <TogglButtonLine />
        </Hamburger>
      </HamburgerWrapper>
      <MainMenu />
      <ResponsiveNavigation className="nav-wrapper" style={navAnimation} />
      {/* <Logo /> */}
      <LayoutWrapper style={pageAnimation}>
        {children}
        <Location>
          {({ location }) => {
            const homePage = location.pathname.split("/")[1]
            if (homePage === "home" || location.pathname === "/blog") {
              return <SocialIcons />
            }
          }}
        </Location>
      </LayoutWrapper>
      <Footer />
    </>
  )
}

export default Layout
