import React from "react"
import { graphql, StaticQuery, Link } from "gatsby"
import styled from "styled-components"
import SiteInfo from "./SiteInfo"

const MainMenuWrapper = styled.div`
  position: relative;
  height: 85px;
  display: flex;
  background-color: #f5f5f5;
  right: 0;
  left: 0;
  z-index: 1030;
`

const MainMenuInner = styled.div`
  max-width: 1400px;
  width: 1400px;
  display: flex;
  margin: 0 auto;
  padding: 0 3rem;
  height: 100%;
`

const MenuItem = styled(Link)`
  font-family: "Poppins", sans-serif;
  color: #6b6b6b;
  display: block;
  align-self: center;
  margin: auto 0;
  font-weight: 500;
  font-size: 14px;
  text-transform: lowercase;
  padding: 12px 16px;
  text-decoration: none;
  transition: ease-in color 0.2s;

  @media (max-width: 768px) {
    display: none;
  }

  &:hover {
    background: -webkit-gradient(
      linear,
      left top,
      right top,
      from(#41aa0a),
      to(#0797b9)
    );
    background: linear-gradient(to right, #41aa0a, #0797b9);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-box-decoration-break: clone;
    box-decoration-break: clone;
    text-shadow: none;
  }

  &.active {
    color: #171717;
  }

  &.active:hover {
    background: -webkit-gradient(
      linear,
      left top,
      right top,
      from(#171717),
      to(#171717)
    );
    background: linear-gradient(to right, #171717, #171717);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-box-decoration-break: clone;
    box-decoration-break: clone;
    text-shadow: none;
  }
`

const MainMenu = () => (
  <StaticQuery
    query={graphql`
      {
        allWordpressWpApiMenusMenusItems(filter: { name: { eq: "Menu" } }) {
          edges {
            node {
              items {
                title
                object_slug
              }
            }
          }
        }
      }
    `}
    render={props => (
      <MainMenuWrapper>
        <MainMenuInner>
          <SiteInfo />
          {props.allWordpressWpApiMenusMenusItems.edges[0].node.items.map(
            item => (
              <MenuItem
                activeClassName="active"
                to={`/${item.object_slug}`}
                key={item.title}
              >
                {item.title}
              </MenuItem>
            )
          )}
        </MainMenuInner>
      </MainMenuWrapper>
    )}
  />
)

export default MainMenu
