import React from "react"
import styled from "styled-components"
import InstagramLogo from "../svg/instagram-logo.svg"
import DribbbleLogo from "../svg/dribbble-logo.svg"
import BehanceLogo from "../svg/behance-logo.svg"

const FooterWrapper = styled.div`
  font-family: "Poppins", sans-serif;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  font-size: 0.9rem;
  font-weight: 400;
  background-color: transparent;
  padding: 1rem 2.5rem 2.5rem 2.5rem;
  margin-bottom: 0;
  text-align: center;
  bottom: 0;
  color: #6b6b6b;
`

const FooterTextWrapper = styled.div`
  text-align: center;
`

const FooterSocialLinksWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  text-align: center;
  margin: 0.4rem 0;
  width: 100%;

  @media (min-width: 768px) {
    display: none;
  }
`

const FooterSocialLinks = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  .social-icon {
    width: 19px;
    background-color: transparent;
    padding: 0;
    margin: 0 1rem;
    text-align: center;
    fill: #6b6b6b;
    -webkit-transition: fill 0.1s ease;
    -ms-transition: fill 0.1s ease;
    transition: fill 0.1s ease;
    cursor: pointer;
    &:hover {
      fill: #0797b9;
    }
  }
`

const Footer = () => (
  <FooterWrapper>
    <FooterTextWrapper>
      <p>
        Philip Lowe | pjlowe.com <i>2019.</i>
      </p>
    </FooterTextWrapper>

    <FooterSocialLinksWrapper>
      <FooterSocialLinks>
        <BehanceLogo className="social-icon" />
        <InstagramLogo className="social-icon" />
        <DribbbleLogo className="social-icon" />
      </FooterSocialLinks>
    </FooterSocialLinksWrapper>
  </FooterWrapper>
)

export default Footer
