import React from "react"
import { graphql, StaticQuery, Link } from "gatsby"
import { animated } from "react-spring"
import styled from "styled-components"

const Navigation = styled(animated.div)`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 100px;
  background: #efefef;
  z-index: 10000;

  a {
    display: block;
    text-align: left;
    font-size: 2.6rem;
    color: #6b6b6b;
    text-decoration: none;
    transition: 0.3s ease border;
    border-bottom: solid 4px transparent;
  }

  a:hover {
    border-bottom: solid 4px var(--teal);
  }
`

const MenuItem = styled(Link)`
  font-family: "Poppins", sans-serif;
  color: #6b6b6b;
  display: block;
  align-self: center;
  margin: auto 0;
  font-size: 14px;
  text-transform: lowercase;
  padding: 12px 16px;
  text-decoration: none;
  transition: ease-in color 0.2s;

  @media (max-width: 768px) {
    display: none;
  }

  &:hover {
    background: -webkit-gradient(
      linear,
      left top,
      right top,
      from(#41aa0a),
      to(#0797b9)
    );
    background: linear-gradient(to right, #41aa0a, #0797b9);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-box-decoration-break: clone;
    box-decoration-break: clone;
    text-shadow: none;
  }
`

const Nav = ({ style }) => (
  <StaticQuery
    query={graphql`
      {
        allWordpressWpApiMenusMenusItems(filter: { name: { eq: "Menu" } }) {
          edges {
            node {
              items {
                title
                object_slug
              }
            }
          }
        }
      }
    `}
    render={props => (
      <Navigation style={style}>
        {props.allWordpressWpApiMenusMenusItems.edges[0].node.items.map(
          item => (
            <MenuItem
              activeClassName="active"
              to={`/${item.object_slug}`}
              key={item.title}
            >
              {item.title}
            </MenuItem>
          )
        )}
      </Navigation>
    )}
  />
)

export default Nav
