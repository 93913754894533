import React from "react"
import Layout from "../components/layout"
import styled from "styled-components"

const TechnologiesLayoutWrapper = styled.div`
  position: relative;
  max-width: 100%;
  text-align: center;
  padding: 1rem 3rem;
`

const FeaturedImage = styled.img`
  max-width: 100%;
  margin: 16px 0;
`

const TechnologiesTitle = styled.h1`
  font-family: "Poppins", sans-serif;
  color: #171717;
  font-size: 45px;
  font-weight: 400;
`

export default ({ pageContext }) => (
  <Layout>
    <TechnologiesLayoutWrapper>
      <TechnologiesTitle>{pageContext.title}</TechnologiesTitle>
      <FeaturedImage src={pageContext.featured_media.source_url} />
      <div dangerouslySetInnerHTML={{ __html: pageContext.content }} />
    </TechnologiesLayoutWrapper>
  </Layout>
)
