import React from "react"
import styled from "styled-components"
import InstagramLogo from "../svg/instagram-logo.svg"
import DribbbleLogo from "../svg/dribbble-logo.svg"
import BehanceLogo from "../svg/behance-logo.svg"

const SocialLinksWrapper = styled.div`
  position: absolute;
  right: 2.5rem;
  top: 146px;
  width: 25px;
  padding: 2.5rem;
  z-index: 10;

  @media (max-width: 768px) {
    display: none;
  }
`

const SocialLinks = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: auto;
  .social-icon {
    background-color: transparent;
    padding: 0;
    margin: 0 0 2.1rem 0;
    text-align: center;
    fill: #6b6b6b;
    -webkit-transition: fill 0.1s ease;
    -ms-transition: fill 0.1s ease;
    transition: fill 0.1s ease;
    cursor: pointer;
    &:last-child {
      margin: 0;
    }
    &:hover {
      fill: #0797b9;
    }
  }
`

const SocialIcons = () => {
  return (
    <>
      <SocialLinksWrapper>
        <SocialLinks>
          <BehanceLogo className="social-icon" />
          <InstagramLogo className="social-icon" />
          <DribbbleLogo className="social-icon" />
        </SocialLinks>
      </SocialLinksWrapper>
    </>
  )
}

export default SocialIcons
